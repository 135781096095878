import React, { useState } from "react";
import PropTypes from "prop-types";
// import hoverEffect from 'hover-effect'
import { Title, Paragraph } from "components/Typography";
import Collapse from "components/Collapse";
import { useIntl } from "gatsby-plugin-intl";
// import useWhatWeDoImages from 'hooks/useWhatWeDoImages'
// import displacementImage from 'assets/images/displacement-serenity.png'
import * as S from "./WhatWeDo.styles";
// import { Image } from 'components/Cases/singleCases/About/About.styles'

export const WhatWeDo = () => {
  const { formatMessage } = useIntl();
  const [currentOpenIndex, setCurrentOpenIndex] = useState();
  // const [firstImage, secondImage] = useWhatWeDoImages()

  // useEffect(() => {
  // const mediaQuery = window.matchMedia('(max-width: 768px)')

  // const image = mediaQuery.matches ? 1.852345415778252 : 1.362745098039216

  //   new hoverEffect({
  //     parent: document.querySelector('#effectOnHover'),
  //     intensity: 0.2,
  //     image2: firstImage.childImageSharp.fluid.src,
  //     image1: secondImage.childImageSharp.fluid.src,
  //     displacementImage: displacementImage,
  //     speedIn: 1.6,
  //     speedOut: 1.6,
  //     imagesRatio: image,
  //   })
  // }, [firstImage.childImageSharp.fluid.src, secondImage.childImageSharp.fluid.src])

  return (
    <S.Wrapper id="whatWeDo">
      <S.Slider />
      <S.Content>
        <Title>{formatMessage({ id: "whatWeDo.title" })}</Title>
        <Paragraph>{formatMessage({ id: "whatWeDo.description" })}</Paragraph>
        <S.CollapseGroup>
          {[...Array(5)].map((_, index) => (
            <Collapse
              key={index}
              title={formatMessage({ id: `whatWeDo.group-${index + 1}.title` })}
              content={formatMessage({
                id: `whatWeDo.group-${index + 1}.description`,
              })}
              onClick={() => setCurrentOpenIndex(index)}
              openIndex={currentOpenIndex}
              index={index}
            />
          ))}
        </S.CollapseGroup>
      </S.Content>
    </S.Wrapper>
  );
};

Collapse.propTypes = {
  title: PropTypes.string.isRequired,
};

export default WhatWeDo;
