import React, { useState } from "react";

import * as S from "./whatsApp.styles";
import WhatsApp from "assets/images/WhatsApp-logo.svg";

const WhatsAppPipe = () => {
  return (
    <S.pip>
      <a href="https://wa.me/5511941648877" target="_blank">
        <S.Image src={WhatsApp} alt="" />
      </a>
    </S.pip>
  );
};
export default WhatsAppPipe;
