import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import IcChevron from 'assets/images/svgs/chevron.svg'
import * as S from './Collapse.styles'

const Collapse = ({ title, content, onClick, openIndex, index }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    if (onClick) {
      onClick()
    }

    setOpen(!open)
  }

  useEffect(() => {
    setOpen(openIndex === index)
  }, [openIndex, index])

  return (
    <S.Wrapper key={title} collapse open={open}>
      <S.CollapseHeader role="button" onClick={handleClick}>
        <S.ItemTitle>
          {title} <IcChevron />
        </S.ItemTitle>
        <S.Description open={open}>{content}</S.Description>
      </S.CollapseHeader>
    </S.Wrapper>
  )
}

Collapse.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  openIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default Collapse
