import styled, { css } from "styled-components";
import media from "styled-media-query";
import WhatsApp from "assets/images/WhatsApp-logo.svg";

export const pip = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  top: 80%;
  left: 90%;
  z-index: 9999 !important;
  @media screen and (max-width: 1200px) {
    top: 80%;
    left: 80%;
  }
  @media screen and (max-width: 480px) {
    top: 80%;
    left: 70%;
  }
`;
export const Image = styled.img`
  width: 80px;
  height: 80px;
  z-index: 9999 !important;
  &:hover {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
`;
