import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  ${({ theme, open = false }) => css`
    border-top: 1px solid ${theme.colors.grayscale.light.info};
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid ${theme.colors.grayscale.light.info};
    }

    ${ItemTitle} {
      svg {
        height: 10px;
        transition: transform 0.3s linear;
        transform: rotate(${open ? '180deg' : '0'});
      }
    }
  `}
`

export const CollapseHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 100%;

    ${media.lessThan('1367px')`
      padding: ${theme.spacings.small} 0;
    `}

    ${media.lessThan('medium')`
      padding: ${theme.spacings.xsmall} 0;
    `}
  `}
`

export const ItemTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.title};
    font-size: ${theme.font.sizes.xlarge};
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.3s linear;
    font-weight: ${theme.font.bold};

    ${media.lessThan('1367px')`
      font-size: ${theme.font.sizes.large};
    `}

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.medium};
    `}
  `}
`

export const Description = styled.span`
  ${({ theme, open }) => css`
    color: ${theme.colors.grayscale.light.paragraph};
    transition: all 0.3s linear;
    height: ${open ? '100%' : '0'};
    opacity: ${open ? '1' : '0'};
    pointer-events: ${open ? 'all' : 'none'};
    margin-top: ${open ? '24px' : '0'};

    ${media.lessThan('1367px')`
      font-size: 14px;
    `}
  `}
`
